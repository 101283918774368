.td-separator {
  @include set-pan;
  @include set-width-s;
  border-color: $mono-color-300;
  border-style: solid;
  border-width: 3px 0 0;
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
