@font-face {
  font-family: "lg";
  src: url("../fonts/lightgallery/lg.eot?n1z373");
  src: url("../fonts/lightgallery/lg.eot?#iefixn1z373")
      format("embedded-opentype"),
    url("../fonts/lightgallery/lg.woff?n1z373") format("woff"),
    url("../fonts/lightgallery/lg.ttf?n1z373") format("truetype"),
    url("../fonts/lightgallery/lg.svg?n1z373#lg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "iconfont";
  src: url("../fonts/iconfont/fonts/iconfont.ttf?z4ih5j") format("truetype"),
    url("../fonts/iconfont/fonts/iconfont.woff?z4ih5j") format("woff"),
    url("../fonts/iconfont/fonts/iconfont.svg?z4ih5j#iconfont") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DiGrotesk";
  src: url("../fonts/digro/DiGrotesk-Black.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "DiGrotesk";
  src: url("../fonts/digro/DiGrotesk-BlackItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "DiGrotesk";
  src: url("../fonts/digro/DiGrotesk-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "DiGrotesk";
  src: url("../fonts/digro/DiGrotesk-BoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "DiGrotesk";
  src: url("../fonts/digro/DiGrotesk-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "DiGrotesk";
  src: url("../fonts/digro/DiGrotesk-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}
