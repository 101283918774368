.Pagination {
  @include set-mvh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  max-width: $mwidth-m;
  .PaginationItem {
  }
}
