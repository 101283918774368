.ContactLayout {
  .EmailBits {
    @include set-mbh;
    @include set-type-m;
    margin-left: auto;
    margin-right: auto;
    max-width: 680px;
    text-align: center;
  }
  .ContactBits {
    @include set-type-s;
    display: flex;
    justify-content: center;
    text-align: left;
    ul {
      position: relative;
      @include bp("tabletUp") {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
    li:not(:first-child) {
      @include set-mtm;
    }
    i {
      @include set-mrs;
      @include set-type-s;
      display: inline-block;
    }
  }
  .Separator {
    @include set-mvh;
  }
}
