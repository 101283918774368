[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-menu:before {
  content: "\ec71";
}
.icon-linkedin:before {
  content: "\ef28";
}
.icon-facebook:before {
  content: "\eef0";
}
.icon-instagram:before {
  content: "\eef1";
}
.icon-traffic:before {
  content: "\e900";
}
