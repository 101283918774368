.ProjectsLayout {
  margin-left: auto;
  margin-right: auto;
  max-width: $mwidth-s;

  .Project {
    &:not(:last-child) {
      @include set-mbh;
    }
    .td-tile__title {
      @include set-type-l;
    }
  }

  .ProjectEl {
    display: block;
    position: relative;
  }

  .ProjectFt {
    @include set-mhl;
    @include set-pal;
    @include set-type-x;
    background: $decor-color;
    position: relative;
    top: -50px;
    z-index: 1;
    @include bp("phone") {
      @include set-mhn;
      @include set-pam;
      top: 0;
    }
    p {
      @include set-type-s;
      color: $mono-color-600;
    }
    p:not(:last-child) {
      @include set-mbx;
    }
    .Action {
      @include set-type-x;
    }
  }
}
