.PostsLayout {
  margin-left: auto;
  margin-right: auto;
  max-width: $mwidth-s;

  .Post {
    &:not(:last-child) {
      @include set-mbh;
    }
  }

  .PostEl {
    display: block;
    position: relative;
  }

  .PostHd {
    @include set-phm;
    align-items: center;
    background: $shadow;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    z-index: 1;
    h2 {
      @include set-type-l;
    }
    span {
      display: inline;
    }
  }
  .PostBd {
  }
  .PostFt {
    @include set-mhl;
    @include set-pal;
    @include set-type-x;
    background: $decor-color;
    position: relative;
    top: -50px;
    z-index: 1;
    @include bp("phone") {
      @include set-mhn;
      @include set-pam;
      top: 0;
    }
    p {
      @include set-type-s;
      color: $mono-color-600;
    }
    p:not(:last-child) {
      @include set-mbx;
    }
    .Action {
      @include set-type-x;
    }
  }
}
