@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  -webkit-animation: fade-in $time-m cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in $time-m cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
