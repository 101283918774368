.Main {
  margin-left: auto;
  margin-right: auto;
  max-width: $mwidth-l;
}

.MainEl {
  @include bp("phone") {
    @include set-phl;
  }
  @include bp("tabletUp") {
    @include set-phh;
  }
}
