.td-tile {
  display: block;
  line-height: 0;
  position: relative;
}

.td-tile__title {
  @include set-type-m;
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  line-height: $leading-m;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  transition: opacity $time-m;
  z-index: 1;
  span {
    background: $mono-color-600;
    color: $mono-color-300;
    font-weight: 600;
    display: inline-block;
    @include set-pax;
    @include set-mal;
    font-family: $brand-font;
  }
  .td-tile:focus &,
  .td-tile:active &,
  .td-tile:hover & {
    opacity: 1;
  }
  &.persist {
    opacity: 1 !important;
  }
}

.td-tile:focus,
.td-tile:hover,
.td-tile:active {
  .td-tile__title {
    opacity: 1;
  }
  .td-tile__cover-overlay {
    opacity: 0;
  }
}

.td-tile__ft {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  transform: translateY(50%);
  z-index: 1;
  & > * {
    @include set-phs;
    @include set-pvx;
    @include set-type-x;
    background-color: $decor-color;
  }
  .td-tile:focus & {
    color: $mono-color-600;
  }
}

.td-tile__cover {
  display: inline-block;
  line-height: 0;
  position: relative;
  > * {
    min-height: 100%;
    min-width: 100%;
  }
  .td-tile__cover-insert {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
  .td-tile__cover-overlay {
    background-blend-mode: luminosity;
    background-color: #000;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    bottom: 0;
    content: " ";
    display: block;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity $time-m;
  }
}
