// colors
$brand-color: #0000ff;
$decor-color: #dafff3;

$shadow: rgba(0, 0, 0, 0.5);

$mono-color-600: #000;
$mono-color-500: #555;
$mono-color-400: #ccc;
$mono-color-300: #f5f5f5;

// fonts
$brand-font: DiGrotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
$body-font: Roboto, sans-serif;

// fsizes
$fsize-h: 28px 60px;
$fsize-l: 19px 30px;
$fsize-m: 17px 24px;
$fsize-s: 15px 18px;
$fsize-x: 14px 15px;

// sizes
$size-h: 48px 96px;
$size-l: 32px 64px;
$size-m: 24px 40px;
$size-s: 16px 24px;
$size-x: 12px 16px;

// spaces
$space-h: 38px 64px;
$space-l: 24px 32px;
$space-m: 12px 16px;
$space-s: 6px 8px;
$space-x: 3px 4px;
$space-n: 0px 0px;

// times
$time-l: 0.5s;
$time-m: 0.3s;
$time-s: 0.1s;

// tracking
$tracking-l: 0.32em;
$tracking-m: 0.16em;
$tracking-s: 0.08em;

// leading
$leading-l: 1.6em;
$leading-m: 1.4em;
$leading-s: 1.2em;

// max-widths
$mwidth-l: 1200px;
$mwidth-m: 950px;
$mwidth-s: 700px;
