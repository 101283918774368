.UpdatesLayout {
  margin-left: auto;
  margin-right: auto;
  max-width: $mwidth-m;

  @include bp("tabletUp") {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .Update {
    flex: 0 0 50%;
    &:not(:last-child) {
      @include set-mbh;
    }
    @include bp("tabletUp") {
      &:nth-child(odd) {
        @include set-prh;
      }
      &:nth-child(even) {
        @include set-plh;
        transform: translateY(40px);
      }
    }
  }

  .UpdateEl {
    display: block;
    position: relative;
  }

  .UpdateHd {
    @include set-phm;
    align-items: center;
    background: $shadow;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    z-index: 1;
    h2 {
      @include set-type-m;
    }
    span {
      display: inline;
    }
  }
  .UpdateBd {
  }
  .UpdateFt {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    z-index: 2;
    span {
      @include set-phm;
      @include set-pvx;
      @include set-type-x;
      background: $decor-color;
      display: inline-block;
      transform: translateY(50%);
    }
  }
}
