.Pitch {
  position: relative;
  .PitchImage {
    position: relative;
    &:after {
      background: $shadow;
      bottom: 0;
      content: " ";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 0;
    }
  }
  .PitchCopy {
    @include set-pal;
    & > div {
      @include set-pah;
      background: $decor-color;
      margin-left: auto;
      margin-right: auto;
      max-width: $mwidth-m;
    }
    h1 {
      @include set-mbm;
      @include set-type-l;
      color: $mono-color-600;
      font-family: $brand-font;
      font-weight: 600;
    }
    p {
      @include set-mtm;
      @include set-mbl;
      @include set-type-x;
      color: $mono-color-500;
      text-transform: uppercase;
    }
  }

  @include bp("phone") {
    .PitchCopy {
      margin-top: -6em;
      position: relative;
      z-index: 1;
    }
  }
  @include bp("tablet") {
    .PitchCopy {
      margin-top: -8em;
      position: relative;
      z-index: 1;
    }
  }
  @include bp("desktopUp") {
    @include set-mbh;
    .PitchCopy {
      align-items: center;
      bottom: 0;
      display: flex;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
