.FooterWrapper {
  @include set-mbh;
  margin-left: auto;
  margin-right: auto;
  max-width: $mwidth-l;
}

.Footer {
  @include set-phl;
  @include set-type-x;
  line-height: 1em;
  @include bp("phone") {
    display: flex;
    flex-direction: column;
    text-align: center;
    & > *:not(:last-child) {
      @include set-mbl;
    }
  }
  @include bp("tabletUp") {
    @include set-phh;
    align-items: flex-start;
    display: flex;
    flex-direction: row-reverse;
    flex: 0 0 25%;
    justify-content: space-between;
    & > *:first-child {
      display: flex;
      align-items: center;
    }
    img {
      @include set-mrm;
    }
  }
  .FooterPartners {
    a {
      display: inline-block;
    }
  }
  .FooterName {
    color: #bababa;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    line-height: 1em;
  }
  .SocialLinks {
    @include set-mls;
    display: inline-block;
  }
  .SocialLink {
    @include set-mhs;
    display: inline-block;
    & > *:first-child {
      @include set-mln;
    }
    & > *:last-child {
      @include set-mrn;
    }
  }
  .Brandmark {
    @include set-height-m;
  }
}
