.WorksLayout {
  .Toolbar {
    @include bp("tabletUp") {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: -1.5em;
      position: relative;
      z-index: 5;
    }
  }
  .Tabs {
    @include set-pvs;
    @include set-type-x;
    background: $decor-color;
    display: inline-block;
    text-align: center;
    @include bp("tabletUp") {
      @include set-pam;
    }
  }
  .Tab {
    display: inline-block;
  }
  .TabLink {
    @include set-max;
    @include set-phs;
    @include set-pvx;
    border-color: transparent;
    border-style: solid;
    border-width: 1px;
    color: $mono-color-600;
    cursor: pointer;
    display: inline-block;
    font-family: $brand-font;
    font-weight: 600;
    white-space: nowrap;
    &.is-active {
      border-color: $mono-color-600;
    }
  }
  // const TabLink = styled.a`
  //   border-color: ${({ isActive, theme }) =>
  //     isActive ? theme.titleColor : "transparent"};
  //   color: ${({ isActive, theme }) =>
  //     isActive ? theme.titleColor : theme.color};
  // `;

  .WorksListing {
    display: grid;
    grid-gap: 5px;
    margin-left: auto;
    margin-right: auto;
    @include bp("phone") {
      grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    }
    @include bp("tabletUp") {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }

    .Work {
    }

    .WorkEl {
      display: block;
      position: relative;
    }

    .WorkHd {
      @include set-phm;
      align-items: center;
      background: $shadow;
      bottom: 0;
      display: flex;
      flex-direction: row;
      height: 100%;
      justify-content: center;
      left: 0;
      overflow: hidden;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      width: 100%;
      z-index: 2;
      h2 {
        @include set-type-m;
        line-height: $leading-m;
      }
      span {
        display: inline;
      }
    }
    .WorkBd {
    }
  }
}
