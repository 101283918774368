.Gallery {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  margin-left: auto;
  margin-right: auto;
  max-width: $mwidth-s;
  @include bp("phone") {
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  }
  @include bp("tablet") {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }
  & > li {
    cursor: pointer;
    grid-row-end: span 1;
    line-height: 1em;
  }
  img {
    width: 100%;
  }
}
