.AboutLayout {
  margin-left: auto;
  margin-right: auto;
  .AboutHd {
    @include set-mbl;
  }
  .AboutBd {
  }
  .AboutFt {
    margin-left: auto;
    margin-right: auto;
    max-width: $mwidth-s;
    h2 {
      @include set-mvh;
      @include set-type-l;
      color: $mono-color-600;
      font-family: $brand-font;
      font-weight: 600;
      text-align: center;
    }
  }
  .PartnersTitle {
    @include set-mvl;
  }
  .Partner {
    position: relative;
    text-align: center;
    img {
      display: inline-block;
      max-width: 80%;
    }
  }
  .td-tile__cover-overlay {
    display: none;
  }
  .td-separator {
    @include set-mvh;
  }
}
